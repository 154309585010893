import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { Shift } from "@src/lib/interface/src";
import moment from "moment";

/**
 * Tries to adjust a time string either one day back or one day forwards, in case it's not within the shift's start and end.
 * @deprecated This legacy behavior is deprecated behind the hcp-can-edit-date-for-multi-day-shifts feature flag.
 */
export function useAdjustTimeForMultiDayShifts(shift: Shift) {
  const flags = useCbhFlags();
  const hcpCanEditDatesForMultiDayShifts =
    flags[CbhFeatureFlag.HCP_CAN_EDIT_DATE_FOR_MULTI_DAY_SHIFTS];

  const adjustTimeForMultiDayShifts = <OptionalTime extends string | undefined>(
    time: OptionalTime
  ): OptionalTime | string => {
    // This "wrap around" logic should be skipped in the new logic.
    if (hcpCanEditDatesForMultiDayShifts) {
      return time;
    }

    if (!time) {
      return time;
    }
    // Since it's legacy logic we'll preserve it as-is
    if (moment(time).isAfter(moment(shift.end).add(2, "h"))) {
      return moment(time).subtract(1, "d").toISOString();
    }
    if (moment(time).isBefore(moment(shift.start).subtract(3, "h"))) {
      return moment(time).add(1, "d").toISOString();
    }
    return time;
  };

  return { adjustTimeForMultiDayShifts };
}
