import "./style.scss";
import { IonContent, IonIcon } from "@ionic/react";
import { Box, Button } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { informationCircleOutline } from "ionicons/icons";
import { useState } from "react";

import { TagLocationModal } from "./tagLocationModal";

export function TagLocationModalLink() {
  const [showTagLocationModal, setShowTagLocationModal] = useState(false);

  const isTagLocationModalEnabled = useCbhFlag(CbhFeatureFlag.ENABLE_TAG_LOCATION_POPUP, {
    defaultValue: false,
  });

  return (
    <IonContent>
      {isTagLocationModalEnabled && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="start"
            height="100%"
            overflow="hidden"
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setShowTagLocationModal(true);
              }}
            >
              Can't find our tag? <IonIcon icon={informationCircleOutline} />
            </Button>
          </Box>
          <TagLocationModal
            show={showTagLocationModal}
            onClose={() => {
              setShowTagLocationModal(false);
            }}
          />
        </>
      )}
    </IonContent>
  );
}
