import { Box } from "@mui/material";
import { ReactNode } from "react";

export type TimesheetSummaryOverlayProps = {
  children: ReactNode;
  paddingTop?: number;
};

/**
 * @deprecated
 * This component is temporary.
 * It is used to inject an overlay UI into existing ionic modal,
 * so appV2 components don't mix well, and don't produce desired results.
 * Remove this component when TimeSheetSummary is refactored and moved to appV2.
 * Convert to MUI components in appV2.
 */
export function DeprecatedTimesheetSummaryOverlay(
  props: TimesheetSummaryOverlayProps
): JSX.Element {
  const { children } = props;
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: 3,
        paddingTop: "env(safe-area-inset-top)",
        paddingBottom: "env(safe-area-inset-bottom)",
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
    >
      {children}
    </Box>
  );
}
