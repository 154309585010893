import { IonButton, IonContent, IonModal } from "@ionic/react";
import "./style.scss";

const TagLocationModal: React.VFC<{
  show: boolean;
  onClose: (boolean) => void;
}> = ({ show, onClose }) => {
  return (
    <IonModal isOpen={show} swipeToClose={true} onDidDismiss={() => onClose(true)}>
      <IonButton slot="end" class="tag-locations-modal-cancel-button" onClick={() => onClose(true)}>
        X
      </IonButton>
      <IonContent className="nfc-tag-location-modal-container">
        <div className="nfc-tag-location-modal-content">
          <h4 className="nfc-tag-location-info title">
            <span>Can't find our tag?</span>
          </h4>
          <p className="nfc-tag-location-info description">
            The tag is placed on a Clipboard Health poster by the check-in desk.
          </p>
          <p className="nfc-tag-location-info description">
            If {"you're"} still having trouble, please <b>contact the facility administrator.</b>
          </p>
        </div>
      </IonContent>
    </IonModal>
  );
};

export { TagLocationModal };
