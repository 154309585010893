import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Button, Stack } from "@mui/material";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { TimeRange } from "@src/lib/interface/src";

export type WorkedBreakConfirmationProps = {
  isTimeEditOpen: boolean;
  lunchInOutTimeRange: TimeRange;
  workedBreakAuthorization: WorkedBreakAuthorizationParams;
  onEditTimesClick: () => void;
  onConfirmClick: () => void;
};

export function WorkedBreakConfirmation(props: WorkedBreakConfirmationProps): JSX.Element {
  const {
    isTimeEditOpen,
    lunchInOutTimeRange,
    workedBreakAuthorization,
    onEditTimesClick,
    onConfirmClick,
  } = props;

  const breakType =
    !isDefined(lunchInOutTimeRange.start) || !isDefined(lunchInOutTimeRange.end)
      ? "skipped"
      : "incomplete";

  return (
    <Box display="flex" flexDirection="column" sx={{ height: "100%", overflowY: "hidden" }}>
      <Stack flexGrow={1} sx={{ height: "100%", overflowY: "auto" }} spacing={1}>
        <Title component="h1" marginBottom={2}>
          Is this correct?
        </Title>
        <Text>
          This timesheet contains {`${breakType === "skipped" ? "a" : "an"} ${breakType}`} break. By
          signing this timesheet,
          <strong>
            you confirm the following statement from the Clipboard professional is true and
            accurate:
          </strong>
        </Text>

        <Text fontStyle="italic">"{workedBreakAuthorization.reason.trim()}"</Text>
        <Text>
          If this timesheet is signed, the facility admin will be notified of the
          {` ${breakType}`} break and will have three business days to accept or dispute the
          additional charge.
        </Text>
      </Stack>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ paddingTop: 1 }}>
        <Button
          variant="contained"
          onClick={onEditTimesClick}
          size="small"
          sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "50%" }}
        >
          Edit break times
        </Button>
        <Button
          variant="outlined"
          onClick={onConfirmClick}
          disabled={isTimeEditOpen}
          size="small"
          sx={{ flexGrow: 1, flexShrink: 1, flexBasis: "50%" }}
        >
          Confirm
        </Button>
      </Stack>
    </Box>
  );
}
