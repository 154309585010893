import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import {
  allowedClockInDifferenceInMinutes,
  allowedClockOutDifferenceInMinutes,
} from "@src/constants/shiftEventTimeDifference";
import { Shift } from "@src/lib/interface/src";
import moment from "moment-timezone";

const FORMAT_FOR_ION_DATETIME_LIMITS = "YYYY-MM-DD";

export function useShiftTimeEditUtils(shift: Shift) {
  const flags = useCbhFlags();
  const hcpCanEditDatesForMultiDayShifts =
    flags[CbhFeatureFlag.HCP_CAN_EDIT_DATE_FOR_MULTI_DAY_SHIFTS];

  const facilityTimezone = shift?.facility?.tmz ?? "";

  const earliestPossibleClockIn = moment
    .tz(shift.start, facilityTimezone)
    .subtract(allowedClockInDifferenceInMinutes, "minutes");

  const latestPossibleClockOut = moment
    .tz(shift.end, facilityTimezone)
    .add(allowedClockOutDifferenceInMinutes, "minutes");

  /**
   * Even if a shift starts and ends on the same day, it might still be a multi-day shift.
   * For example, if it starts at 12:30am, the clock-in can be adjusted to the previous day at 11:30pm.
   */
  const isMultiDayShift = !earliestPossibleClockIn.isSame(latestPossibleClockOut, "day");

  const dayPartIsEditable = hcpCanEditDatesForMultiDayShifts && isMultiDayShift;

  const timeEditLimits = dayPartIsEditable
    ? {
        min: earliestPossibleClockIn.format(FORMAT_FOR_ION_DATETIME_LIMITS),
        max: latestPossibleClockOut.format(FORMAT_FOR_ION_DATETIME_LIMITS),
      }
    : {
        min: undefined,
        max: undefined,
      };

  return {
    timeEditLimits,
    dayPartIsEditable,
  };
}
